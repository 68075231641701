import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MacroprojectsService } from '@app/core/services/macroprojects.service';

import {
    Macroproject,
    MacroprojectCluster,
    MacroprojectClusterCategory,
    MacroprojectRoadmap,
    MacroprojectType,
    MacroprojectTypology,
} from '@app/shared/models/macroproject';

@Component({
    selector: 'ppm-cluster-form-dialog',
    templateUrl: './cluster-form-dialog.component.html',
    styleUrls: ['./cluster-form-dialog.component.scss'],
})
export class ClusterFormDialogComponent implements OnInit {
    public clusterForm!: FormGroup;
    public macroprojectRoadmaps: MacroprojectRoadmap[] = [];
    public macroprojectTypes: MacroprojectType[] = [];
    public macroprojectTypologies: MacroprojectTypology[] = [];
    public macroprojectClusters: MacroprojectCluster[] = [];
    public macroprojectClusterCategories: MacroprojectClusterCategory[] = [];
    public filteredMacroprojectClusterCategories: MacroprojectClusterCategory[] = [];

    get clusterField(): FormControl {
        return this.clusterForm.get('cluster') as FormControl;
    }

    get clusterCategoryField(): FormControl {
        return this.clusterForm.get('cluster_category') as FormControl;
    }

    constructor(
        private dialogRef: MatDialogRef<ClusterFormDialogComponent>,
        private formBuilder: FormBuilder,
        private macroprojectsService: MacroprojectsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.getMacroprojectRoadmaps();
        this.getMacroProjectTypes();
        this.getMacroProjectTypologies();
        this.getMacroprojectClusters();
        this.getMacroprojectClusterCategories();
        this.createForm();
    }

    getMacroprojectRoadmaps(): void {
        this.macroprojectsService
            .getMacroprojectRoadmaps()
            .subscribe((response: MacroprojectRoadmap[]) => (this.macroprojectRoadmaps = response));
    }

    getMacroProjectTypes(): void {
        this.macroprojectsService.getMacroprojectTypes().subscribe((response: MacroprojectType[]) => (this.macroprojectTypes = response));
    }

    getMacroProjectTypologies(): void {
        this.macroprojectsService
            .getMacroprojectTypologies()
            .subscribe((response: MacroprojectTypology[]) => (this.macroprojectTypologies = response));
    }

    getMacroprojectClusters(): void {
        this.macroprojectsService.getClusters().subscribe((response: MacroprojectCluster[]) => (this.macroprojectClusters = response));
    }

    getMacroprojectClusterCategories(): void {
        this.macroprojectsService
            .getClusterCategories()
            .subscribe(
                (response: MacroprojectClusterCategory[]) =>
                    (this.macroprojectClusterCategories = this.filteredMacroprojectClusterCategories = response)
            );
    }

    onClusterSelected(clusterId: number | null): void {
        this.filteredMacroprojectClusterCategories =
            clusterId === null
                ? this.macroprojectClusterCategories
                : this.macroprojectClusterCategories.filter((category: MacroprojectClusterCategory) => category.cluster.id === clusterId);

        this.clusterCategoryField?.reset();
        this.clusterCategoryField?.updateValueAndValidity();
    }

    onClusterCategorySelected(clusterCategoryId: number | null): void {
        const selectedClusterCategory = this.macroprojectClusterCategories.find(
            (category: MacroprojectClusterCategory) => category.id === clusterCategoryId
        );

        if (selectedClusterCategory) {
            const relatedClusterId =
                this.macroprojectClusters.find((cluster: MacroprojectCluster) => cluster.id === selectedClusterCategory.cluster.id)?.id ??
                null;
            this.clusterField?.setValue(relatedClusterId);
            this.clusterField?.updateValueAndValidity();
            this.filteredMacroprojectClusterCategories = this.macroprojectClusterCategories.filter(
                (category: MacroprojectClusterCategory) => category.cluster.id === relatedClusterId
            );
        }
    }

    createForm(): void {
        const { required } = Validators;

        this.clusterForm = this.formBuilder.group({
            roadmap: [this.data.macroproject.roadmap?.id, required],
            cluster: [this.data.macroproject.cluster?.id, null],
            cluster_category: [this.data.macroproject.cluster_category?.id, null],
            typology: [this.data.macroproject.typology?.id, null],
            project_type: [this.data.macroproject.project_type?.id, null],
            board_member: [this.data.macroproject.board_member, null],
            approved: [this.data.macroproject.approved, null],
        });
    }

    onSubmit(): void {
        if (this.clusterForm.valid) {
            const payload = this.clusterForm.value;

            this.macroprojectsService
                .updateMacroproject(this.data.macroproject.id, payload)
                .subscribe((response: Macroproject) => this.dialogRef.close(response));
        }
    }
}
