<form class="ppm-macroprojectForm__wrapper" [formGroup]="clusterForm" (ngSubmit)="onSubmit()">
    <h1 mat-dialog-title>Edit Macroproject: {{ data.macroproject.name }}</h1>

    <div mat-dialog-content>
        <div class="ppm-macroprojectForm__fields">
            <mat-form-field appearance="outline">
                <mat-label>Roadmap</mat-label>
                <mat-select formControlName="roadmap">
                    <mat-option *ngFor="let roadmap of macroprojectRoadmaps" [value]="roadmap.id">
                        {{ roadmap.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="clusterForm.get('roadmap')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Cluster</mat-label>
                <mat-select formControlName="cluster" (valueChange)="onClusterSelected($event)">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let cluster of macroprojectClusters" [value]="cluster.id">
                        {{ cluster.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="clusterForm.get('priority')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Project category</mat-label>
                <mat-select formControlName="typology">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let tipology of macroprojectTypologies" [value]="tipology.id">
                        {{ tipology.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="clusterForm.get('priority')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Project type</mat-label>
                <mat-select formControlName="project_type">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let type of macroprojectTypes" [value]="type.id">
                        {{ type.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="clusterForm.get('project_type')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Subcategory</mat-label>
                <mat-select formControlName="cluster_category" (valueChange)="onClusterCategorySelected($event)">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let priority of filteredMacroprojectClusterCategories" [value]="priority.id">
                        {{ priority.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="clusterForm.get('cluster_category')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>Board member</mat-label>
                <input matInput formControlName="board_member" />
                <mat-error *ngIf="clusterForm.get('board_member')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Approve macroproject</mat-label>
                <mat-select formControlName="approved">
                    <mat-option [value]="null"> - </mat-option>
                    <mat-option [value]="true"> Approved </mat-option>
                    <mat-option [value]="false"> Not Approved </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button type="button" color="primary" mat-dialog-close>CANCEL</button>
        <button mat-flat-button type="submit" color="primary">SAVE</button>
    </div>
</form>
